<template>
  <div class="position-relative">
    <canvas :id="id" width="300" height="300"></canvas>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      required: true,
      type: String,
    },
    chartData: {
      required: true,
      type: Array,
    },
    label: {
      required: true,
      type: Array,
    },
    text: {
      require: false,
    },
    filterValue: {
      require: false,
      type: String,
    },
    xText: {
      require: false,
    },
  },
  mounted() {
    this.initChart();
  },
  methods: {
    addCommas(nStr) {
      var x, x1, x2;

      nStr += "";
      x = nStr.split(".");
      x1 = x[0];
      x2 = x.length > 1 ? "." + x[1] : "";
      var rgx = /(\d+)(\d{3})/;
      while (rgx.test(x1)) {
        x1 = x1.replace(rgx, "$1" + "," + "$2");
      }
      return x1 + x2;
    },
    async initChart() {
      var ctx = document.getElementById(this.id);
      var that = this;
      // ---
      var myChart = new this.$Chart(ctx, {
        type: "pie",
        data: {
          labels: this.label,
          datasets: this.chartData,
        },
        options: {
          layout: {
            padding: { top: 0, right: 0 },
          },
          plugins: {
            datalabels: {
              display:
                this.$store.state.theme.themeLogo.result
                  .show_dashboard_tooltips == "0"
                  ? true
                  : false,
              align: "center",
              anchor: "center",
            },
          },
          tooltips: {
            intersect: false,
            mode: "point",
            callbacks: {
              label: function (tooltipItem, data) {
                let index = tooltipItem.index;
                var datasetLabel = data.labels[index] || "Other";
                var percent = data.datasets[0].data[index];
                var value = data.datasets[0].dataRaw[index].data;
                return `${datasetLabel}: ${that.addCommas(
                  value
                )} (${that.addCommas(percent)}%)`;
              },
            },
          },

          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: true,
            align: "center",
            position: "bottom",
            labels: {
              fontSize: 12,
              boxWidth: 14,
              boxHeight: 14,
              fontFamily: "Kanit-Regular",
              fontColor: "black",
            },
          },
          interaction: {
            mode: "index",
          },
          scales: {
            yAxes: [],
            xAxes: [],
          },
        },
      });

      // document.getElementById("legend_" + this.id).innerHTML =
      //   myChart.generateLegend();
    },
    destroyChart() {},
  },
};
</script>

<style lang="scss" scoped>
// .legend {
//   position: absolute;
//   right: 0;
//   top: 50%;
//   transform: translate(0, -50%);
// }
.chart-container {
  // margin-right: 100px;
  width: 100%;
}
ul {
  padding-inline-start: 0px !important;
}
@media screen and (max-width: 600px) {
  .chart-container {
    margin-bottom: 20px;
    margin-right: unset;
  }
  // .legend {
  //   bottom: 0;
  //   right: 50%;
  //   top: 100%;

  //   transform: translate(50%, -20px);
  //   ul {
  //     flex-direction: row !important;
  //     gap: 5px;
  //     li {
  //       margin-bottom: 0 !important;
  //     }
  //     span {
  //       font-size: 9px !important;
  //       width: max-content;
  //       max-width: 80px !important;
  //     }
  //   }
  // }
}
</style>
